import React, { useState } from 'react'
import { TextField, Button, Typography, Box } from '@mui/material'
import { queryContentWithReferences } from '../services/queryService'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

export default function ContentQueryWithReferences() {
  const [question, setQuestion] = useState('')
  const [answer, setAnswer] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const handleSubmit = async () => {
    setLoading(true)
    setError(null)
    setAnswer(null)
    try {
      const response = await queryContentWithReferences(question)
      setAnswer(response || 'No relevant content found for the query')
    } catch (err) {
      setError('Failed to fetch the answer. Please try again.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box sx={{ maxWidth: 800, mx: 'auto', mt: 4, p: 2 }}>
      <TextField
        label="Ask a question"
        variant="outlined"
        fullWidth
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
        disabled={loading}
      />
      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 2 }}
        onClick={handleSubmit}
        disabled={loading || !question.trim()}
      >
        {loading ? 'Loading...' : 'Ask'}
      </Button>
      {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}
      {answer && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6">Answer:</Typography>
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>
            {answer}
          </ReactMarkdown>
        </Box>
      )}
    </Box>
  )
}