// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyDdtOc3B7Bw9d5mS3OkGC4TsAkHOpN1GEk",
  authDomain: "www.feedsion.com",
  projectId: "feedsion-com",
  storageBucket: "feedsion-com.appspot.com",
  messagingSenderId: "120240549303",
  appId: "1:120240549303:web:1079a5c9d6d73975e58c32",
  measurementId: "G-WZLSFQHSQY"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6Lfn4y4qAAAAAE0oGHFMqgP9sc9tcJXoX7d1Re1D'),
  isTokenAutoRefreshEnabled: true
});

export function initializeFunctions() {
  const functions = getFunctions();
  if (process.env.NODE_ENV === 'development') {
    connectFunctionsEmulator(functions, "127.0.0.1", 5001);
  }
  return functions;
}
