import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import DashboardLayout from './components/DashboardLayout';
import Home from './pages/Home';
import Topic from './pages/Topic';
import Login from './pages/Login';
import Content from './pages/Content';
import Video from './pages/Video';
import ContentQueryWithReferences from './components/ContentQueryWithReferences';
import Landing from './pages/Landing';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route element={<DashboardLayout />}>
            <Route path="/home" element={<Home />} />
            <Route path="/topic" element={<Topic />} />
            <Route path="/query" element={<ContentQueryWithReferences />} />
            <Route path="/login" element={<Login />} />
            <Route path="/content/:topicId" element={<Content />} />
            <Route path="/video/:videoId" element={<Video />} />
          </Route>
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
