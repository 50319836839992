import React, { useEffect, useRef, useState } from 'react'
import { Box, Typography, Container, Paper, LinearProgress, Grid, Icon, Button } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import FilterListIcon from '@mui/icons-material/FilterList'
import SpeedIcon from '@mui/icons-material/Speed'
import InterestsIcon from '@mui/icons-material/Interests'
import ExploreIcon from '@mui/icons-material/Explore'
import SearchIcon from '@mui/icons-material/Search'
import queryImage from '../assets/images/query01.webp'

const sources = ['YouTube', 'Yahoo Finance', 'Twitter', 'Reddit', 'TechCrunch', 'Bloomberg']
const words = ['AI', 'Machine Learning', 'Blockchain', 'Crypto', 'AI Agent', 'Web3', 'Metaverse', 'IoT', 'ChatGPT', 'Cloud Computing']

const theme = createTheme({
  palette: {
    background: {
      default: '#F0F8FF',
      paper: '#FFFFFF',
    },
    primary: {
      main: '#4285F4',
    },
    secondary: {
      main: '#34A853',
    },
  },
})

function Landing() {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [aggregatedWord, setAggregatedWord] = useState('')
  const [aggregatedSource, setAggregatedSource] = useState('')
  const [counter, setCounter] = useState(1000000)

  useEffect(() => {
    const canvas = canvasRef.current
    if (!canvas) return

    const ctx = canvas.getContext('2d')
    if (!ctx) return

    canvas.width = window.innerWidth
    canvas.height = window.innerHeight

    type Particle = {
      x: number
      y: number
      size: number
      speed: number
      text: string
      source: string
      opacity: number
      color: string
    }

    const particles: Particle[] = []

    const colors = ['#4285F4', '#EA4335', '#FBBC05', '#34A853', '#FF6D01', '#46BDC6']

    function createParticle(): Particle {
      const angle = Math.random() * Math.PI * 2
      const width = canvas?.width ?? window.innerWidth
      const height = canvas?.height ?? window.innerHeight
      const radius = Math.max(width, height) / 2
      return {
        x: width / 2 + Math.cos(angle) * radius,
        y: height / 2 + Math.sin(angle) * radius,
        size: 10 + Math.random() * 20,
        speed: 1 + Math.random() * 3,
        text: words[Math.floor(Math.random() * words.length)],
        source: sources[Math.floor(Math.random() * sources.length)],
        opacity: 1,
        color: colors[Math.floor(Math.random() * colors.length)]
      }
    }

    for (let i = 0; i < 50; i++) {
      particles.push(createParticle())
    }

    function draw() {
      if (!canvas || !ctx) return

      ctx.clearRect(0, 0, canvas.width, canvas.height)

      const centerX = canvas.width / 2
      const centerY = canvas.height / 2
      const boxWidth = 400  // Approximate width of the central box
      const boxHeight = 300 // Approximate height of the central box

      particles.forEach((p, index) => {
        ctx.globalAlpha = p.opacity
        ctx.font = `${p.size}px Arial`
        ctx.fillStyle = p.color
        ctx.textAlign = 'center'
        ctx.fillText(p.text, p.x, p.y)

        ctx.font = `${p.size * 0.6}px Arial`
        ctx.fillStyle = p.color
        ctx.fillText(p.source, p.x, p.y + p.size)

        // Move particles towards the center
        const dx = centerX - p.x
        const dy = centerY - p.y
        const distance = Math.sqrt(dx * dx + dy * dy)

        if (distance > boxWidth / 2) {
          p.x += (dx / distance) * p.speed
          p.y += (dy / distance) * p.speed
        } else {
          // If close to the box, start fading out
          p.opacity -= 0.05
          if (p.opacity <= 0) {
            // Replace the particle with a new one
            particles[index] = createParticle()
          }
        }
      })

      requestAnimationFrame(draw)
    }

    draw()

    const aggregateInterval = setInterval(() => {
      const randomParticle = particles[Math.floor(Math.random() * particles.length)]
      setAggregatedWord(randomParticle.text)
      setAggregatedSource(randomParticle.source)
    }, 2000)

    return () => {
      clearInterval(aggregateInterval)
    }
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(prevCount => prevCount + Math.floor(Math.random() * 100) + 1)
    }, 100)

    return () => clearInterval(interval)
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ backgroundColor: 'background.default', overflowX: 'hidden' }}>
        <Box sx={{ position: 'relative', minHeight: '100vh' }}>
          <canvas ref={canvasRef} style={{ position: 'absolute', top: 0, left: 0, zIndex: 1 }} />
          <Box
            sx={{
              position: 'relative',
              zIndex: 2,
              minHeight: '100vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Container maxWidth="sm">
              <Paper
                elevation={3}
                sx={{
                  padding: 4,
                  backgroundColor: 'rgba(255, 255, 255, 0.9)',
                  border: '2px solid #4285F4',
                  borderRadius: 2,
                }}
              >
                <Typography variant="h4" gutterBottom sx={{ color: '#4285F4', textAlign: 'center' }}>
                  AI-powered Feed Fusion
                </Typography>
                <Box sx={{ my: 2 }}>
                  <Box sx={{ 
                    my: 2, 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center'
                  }}>
                    <Box>
                      <Typography variant="body1" sx={{ color: '#34A853' }}>
                        Latest Aggregated Data:
                      </Typography>
                      <Typography variant="h5" sx={{ color: '#EA4335', mt: 1 }}>
                        {aggregatedWord}
                      </Typography>
                      <Typography variant="body2" sx={{ color: '#FBBC05', mt: 1 }}>
                        Source: {aggregatedSource}
                      </Typography>
                    </Box>
                    <Box sx={{ textAlign: 'right' }}>
                      <Typography variant="body2" sx={{ color: '#4285F4' }}>
                        Data Points Processed:
                      </Typography>
                      <Typography variant="h4" sx={{ color: '#EA4335', fontWeight: 'bold' }}>
                        {counter.toLocaleString()}
                      </Typography>
                    </Box>
                  </Box>
                  <LinearProgress 
                    sx={{ 
                      my: 2, 
                      backgroundColor: 'rgba(66, 133, 244, 0.2)',
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: '#4285F4'
                      }
                    }} 
                  />
                  <Typography variant="body2" sx={{ color: '#46BDC6', textAlign: 'center', mt: 2 }}>
                    Continuously aggregating data from multiple sources...
                  </Typography>
                </Box>
              </Paper>
            </Container>
          </Box>
        </Box>

        {/* Features section */}
        <Box sx={{ py: 8, backgroundColor: 'background.paper' }}>
          <Container maxWidth="lg" sx={{ px: { xs: 2, sm: 3, md: 4 } }}>
            <Typography variant="h3" align="center" gutterBottom sx={{ color: 'primary.main', fontWeight: 'bold', mb: 6 }}>
              What Feedsion Does for You
            </Typography>
            <Grid container spacing={4}>
              {[
                {
                  icon: <FilterListIcon fontSize="large" />,
                  title: 'Reduces Information Overload',
                  description: 'Our smart filters cut through the noise, delivering only the most relevant content tailored to your interests.'
                },
                {
                  icon: <InterestsIcon fontSize="large" />,
                  title: 'Personalizes Your Content',
                  description: 'Enjoy a curated feed that adapts to your preferences, sourcing from various platforms you care about.'
                },
                {
                  icon: <SpeedIcon fontSize="large" />,
                  title: 'Saves Your Time',
                  description: 'Quickly grasp key points with AI-powered summaries, allowing for efficient consumption of news and information.'
                },
                {
                  icon: <ExploreIcon fontSize="large" />,
                  title: 'Enhances Content Discovery',
                  description: 'Explore new topics and sources through our intuitive, user-friendly interface designed for easy navigation and management.'
                }
              ].map((feature, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <Paper 
                    elevation={3}
                    sx={{
                      p: 3,
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                      transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                      '&:hover': {
                        transform: 'translateY(-5px)',
                        boxShadow: 6,
                      }
                    }}
                  >
                    <Icon sx={{ fontSize: 40, color: 'secondary.main', mb: 2 }}>
                      {feature.icon}
                    </Icon>
                    <Typography variant="h6" component="h3" gutterBottom sx={{ color: 'text.primary', fontWeight: 'bold' }}>
                      {feature.title}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary', flexGrow: 1 }}>
                      {feature.description}
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>

        {/* New AI Query Feature Section */}
        <Box sx={{ py: 8, backgroundColor: 'background.default' }}>
          <Container maxWidth="lg">
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} md={6}>
                <Box sx={{ 
                  width: '100%',
                  height: { xs: '300px', md: '400px' },
                  position: 'relative',
                  overflow: 'hidden',
                  borderRadius: '8px',
                }}>
                  <img 
                    src={queryImage}  
                    alt="AI Query Feature Demo"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h4" gutterBottom sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                  Ask AI, Get Insights
                </Typography>
                <Typography variant="body1" paragraph sx={{ color: 'text.secondary', mb: 3 }}>
                  Curious about specific topics? Just ask! Our AI-powered system can query and analyze the latest news for you. For example, ask:
                </Typography>
                <Typography variant="h6" sx={{ color: 'secondary.main', fontStyle: 'italic', mb: 3 }}>
                  "How are financial businesses applying AI to boost productivity?"
                </Typography>
                <Typography variant="body1" paragraph sx={{ color: 'text.secondary', mb: 3 }}>
                  Feedsion will search through all relevant news published in the last 24 hours or 7 days, aggregate the information, and provide you with a comprehensive summary. You can receive this insight via email or see it highlighted at the top of your feed.
                </Typography>
                <Button 
                  variant="contained" 
                  color="primary" 
                  size="large" 
                  startIcon={<SearchIcon />}
                  sx={{ mt: 2 }}
                >
                  Try AI Query
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default Landing
