import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Typography, Divider, Container } from '@mui/material';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, onAuthStateChanged, GoogleAuthProvider, OAuthProvider, signInWithPopup } from "firebase/auth";
import { app } from "../services/firebase";
import GoogleIcon from '../components/Login/GoogleIcon';
import MicrosoftIcon from '../components/Login/MicrosoftIcon';
import { useNavigate } from 'react-router-dom';

function Login() {
	const [user, setUser] = useState(null);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [isSignUp, setIsSignUp] = useState(false);
	const [error, setError] = useState('');
	const navigate = useNavigate();

	useEffect(() => {
		const auth = getAuth(app);
		const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
			if (currentUser) {
				navigate('/home');
			}
		});

		return () => unsubscribe();
	}, [navigate]);

	const handleAuth = async (e) => {
		e.preventDefault();
		setError('');
		const auth = getAuth(app);

		try {
			const authFunction = isSignUp ? createUserWithEmailAndPassword : signInWithEmailAndPassword;
			await authFunction(auth, email, password);
			navigate('/home');
		} catch (error) {
			setError(error.message);
		}
	};

	const handleSSOSignIn = async (provider) => {
		const auth = getAuth(app);
		try {
			await signInWithPopup(auth, provider);
			navigate('/home');
		} catch (error) {
			setError(error.message);
		}
	};

	const handleGoogleSignIn = () => handleSSOSignIn(new GoogleAuthProvider());

	const handleMicrosoftSignIn = () => {
		const provider = new OAuthProvider('microsoft.com');
		provider.setCustomParameters({ prompt: 'select_account' });
		handleSSOSignIn(provider);
	};

	if (user) return null; // User is already logged in, don't render the login form

	return (
		<Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
			<Box component="form" onSubmit={handleAuth} sx={{ width: '100%' }}>
				<Typography variant="h5" align="center" sx={{ mb: 3 }}>
					{isSignUp ? 'Sign Up' : 'Sign In'}
				</Typography>
				<TextField
					margin="normal"
					required
					fullWidth
					id="email"
					label="Email Address"
					name="email"
					autoComplete="email"
					autoFocus
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>
				<TextField
					margin="normal"
					required
					fullWidth
					name="password"
					label="Password"
					type="password"
					id="password"
					autoComplete="current-password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
				/>
				{error && <Typography color="error" align="center">{error}</Typography>}
				<Button
					type="submit"
					fullWidth
					variant="contained"
					sx={{ mt: 3, mb: 2 }}
				>
					{isSignUp ? 'Sign Up' : 'Sign In'}
				</Button>
				<Button
					fullWidth
					onClick={() => setIsSignUp(!isSignUp)}
				>
					{isSignUp ? 'Already have an account? Sign In' : "Don't have an account? Sign Up"}
				</Button>
				<Divider sx={{ my: 2 }}>OR</Divider>
				<Button
					fullWidth
					variant="outlined"
					startIcon={<GoogleIcon />}
					onClick={handleGoogleSignIn}
					sx={{ mt: 1, mb: 2 }}
				>
					Sign in with Google
				</Button>
				<Button
					fullWidth
					variant="outlined"
					startIcon={<MicrosoftIcon />}
					onClick={handleMicrosoftSignIn}
					sx={{ mt: 1, mb: 2 }}
				>
					Sign in with Microsoft
				</Button>
			</Box>
		</Container>
	);
}

export default Login;