import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { getFunctions, httpsCallable } from "firebase/functions";
import { Box, Typography, Card, CardContent, CardMedia, CircularProgress, Link, Grid, Pagination } from '@mui/material';
import { initializeFunctions } from '../services/firebase';
import { useAuth } from '../contexts/AuthContext';
import { getAuth, getIdToken } from 'firebase/auth';

interface Content {
  id: string;
  title: string;
  url: string;
  publishDate: {
    _seconds: number;
    _nanoseconds: number;
  };
}

const getYouTubeVideoId = (url: string): string | null => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regExp);
  return (match && match[2].length === 11) ? match[2] : null;
};

export default function Content() {
  const { user } = useAuth();
  const { topicId } = useParams<{ topicId: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const [content, setContent] = useState<Content[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 8;

  const queryParams = new URLSearchParams(location.search);
  const currentPage = parseInt(queryParams.get('page') || '1', 10);

  const fetchContentForTopic = async (topicId: string, page: number, limit: number) => {
    setLoading(true);
    setError(null);
    try {
      const functions = initializeFunctions();
      const getContentForTopic = httpsCallable(functions, 'getContentForTopic');
      const idToken = await getIdTokenResult();
      const result = await getContentForTopic({ topicId, page, limit, idToken });
      return result.data as { content: Content[], total: number };
    } catch (err) {
      console.error("Error fetching content:", err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const getIdTokenResult = async () => {
    if (!user) throw new Error('User not authenticated');
    const auth = getAuth();
    return await getIdToken(auth.currentUser!, true);
  };

  const formatDate = (timestamp: Content['publishDate']) => {
    const date = new Date(timestamp._seconds * 1000);
    return date.toLocaleString();
  };

  const topRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (topicId && user) {
      fetchContentForTopic(topicId, currentPage, itemsPerPage).then(result => {
        setContent(result.content);
        setTotalPages(Math.ceil(result.total / itemsPerPage));
        topRef.current?.scrollIntoView({ behavior: 'smooth' });
      }).catch(err => {
        setError('Failed to fetch content. Please try again.');
        console.error('Error fetching content:', err);
      });
    }
  }, [topicId, user, currentPage]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    navigate(`/content/${topicId}?page=${value}`);
  };

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box ref={topRef}>
      <Grid container spacing={2}>
        {content.map(item => {
          const videoId = getYouTubeVideoId(item.url);
          const thumbnailUrl = videoId ? `https://img.youtube.com/vi/${videoId}/0.jpg` : null;
          return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
              <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                {thumbnailUrl && (
                  <Box 
                    sx={{ 
                      position: 'relative', 
                      paddingTop: '56.25%', 
                      cursor: 'pointer' 
                    }}
                    onClick={() => videoId && navigate(`/video/${videoId}`)}
                  >
                    <CardMedia
                      component="img"
                      image={thumbnailUrl}
                      alt={item.title}
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                  </Box>
                )}
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h6" component="div" gutterBottom>
                    {videoId ? (
                      <Link 
                        component={RouterLink} 
                        to={`/video/${videoId}`}
                        sx={{ textDecoration: 'none', color: 'inherit' }}
                      >
                        {item.title}
                      </Link>
                    ) : (
                      <Link 
                        href={item.url} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        sx={{ textDecoration: 'none', color: 'inherit' }}
                      >
                        {item.title}
                      </Link>
                    )}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Published: {formatDate(item.publishDate)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      {loading && <CircularProgress sx={{ display: 'block', margin: '20px auto' }} />}
      {!loading && content.length > 0 && (
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          mt: 4, 
          // mb: 4, // Add margin at the bottom
          // pb: 4  // Add padding at the bottom
        }}>
          <Pagination 
            variant="outlined"
            size="large"
            count={totalPages} 
            page={currentPage} 
            onChange={handlePageChange}
            color="primary"
          />
        </Box>
      )}
      {!loading && content.length === 0 && (
        <Typography sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          No content available.
        </Typography>
      )}
      <Box sx={{ height: '30px' }} />
    </Box>
  );
}