import { getFunctions, httpsCallable } from 'firebase/functions'
import { initializeFunctions } from './firebase'

interface QueryRequest {
  question: string
}

interface QueryResponse {
  answer: string
}

export const queryContentWithReferences = async (question: string): Promise<string> => {
  const functions = initializeFunctions()
  const queryFunction = httpsCallable<QueryRequest, QueryResponse>(functions, 'queryContentWithReferences')
  
  try {
    const result = await queryFunction({ question })
    return result.data.answer
  } catch (error) {
    console.error('Error querying content with references:', error)
    throw error
  }
}