import React from 'react'
import { Box, Typography, Paper, Button } from '@mui/material'
import { useAuth } from '../contexts/AuthContext'
import { getAuth, signOut } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'

function Home() {
  const { user } = useAuth()
  const navigate = useNavigate()

  const handleLogout = async () => {
    try {
      const auth = getAuth()
      await signOut(auth)
      navigate('/login')
    } catch (error) {
      console.error('Error signing out:', error)
    }
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Paper elevation={3} sx={{ padding: 4, maxWidth: 400 }}>
        <Typography variant="h4" gutterBottom>
          Welcome to Our App
        </Typography>
        {user ? (
          <>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Hello, {user.displayName || user.email}! We're glad you're here.
            </Typography>
            <Button variant="contained" color="primary" onClick={handleLogout}>
              Logout
            </Button>
          </>
        ) : (
          <Typography variant="body1">
            Please sign in to access all features.
          </Typography>
        )}
      </Paper>
    </Box>
  )
}

export default Home