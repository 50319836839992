import React from 'react';
import { DashboardLayout as ToolpadDashboardLayout } from '@toolpad/core/DashboardLayout';
import { AppProvider } from '@toolpad/core/AppProvider';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import TopicIcon from '@mui/icons-material/Topic';
import LoginIcon from '@mui/icons-material/Login';

const NAVIGATION = [
  {
    segment: 'home',
    title: 'Home',
    icon: <HomeIcon />,
  },
  {
    segment: 'topic',
    title: 'Topic',
    icon: <TopicIcon />,
  },
  {
    segment: 'query',
    title: 'Query',
    icon: <TopicIcon />,
  },
  {
    segment: 'login',
    title: 'Login',
    icon: <LoginIcon />,
  },
];

const BRANDING = {
  title: 'Feedsion',
  // logo: <img src="https://mui.com/static/logo.png" alt="MUI logo" />,
};

function DashboardLayout() {
  return (
    <AppProvider
      navigation={NAVIGATION}
      branding={BRANDING}
    >
      <ToolpadDashboardLayout>
        <Outlet />
      </ToolpadDashboardLayout>
    </AppProvider>
  );
}

export default DashboardLayout;
