import React, { useState, useEffect } from 'react'
import { Box, Typography, TextField, Button, IconButton, Snackbar, CircularProgress, MenuItem, Alert } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { useAuth } from '../contexts/AuthContext'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { getAuth, getIdToken } from 'firebase/auth'
import { initializeFunctions } from '../services/firebase'
import { useNavigate } from 'react-router-dom'
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LanguageIcon from '@mui/icons-material/Language';
import { useTheme, useMediaQuery } from '@mui/material';
import { useTheme as useThemeStyles } from '@mui/material/styles';

interface Topic {
  id: string
  keyword: string
  rss_link: string
  type: 'video' | 'article'
}

interface GetTopicsResult {
  topics: Topic[]
}

function Topic() {
  const [topics, setTopics] = useState<Topic[]>([])
  const [newTopicKeyword, setNewTopicKeyword] = useState('')
  const [newTopicRssLink, setNewTopicRssLink] = useState('')
  const [newTopicType, setNewTopicType] = useState<'video' | 'article'>('video')
  const [error, setError] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useAuth()
  const functions = initializeFunctions()
  const navigate = useNavigate()
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const themeStyles = useThemeStyles();

  useEffect(() => {
    if (user) {
      fetchTopics()
    }
  }, [user])

  const getIdTokenResult = async () => {
    if (!user) throw new Error('User not authenticated')
    const auth = getAuth()
    return await getIdToken(auth.currentUser!, true)
  }

  const fetchTopics = async () => {
    setIsLoading(true)
    try {
      const idToken = await getIdTokenResult()
      const getTopicsFunction = httpsCallable<unknown, GetTopicsResult>(functions, 'getTopics')
      const result = await getTopicsFunction({ idToken })
      setTopics(result.data.topics)
    } catch (error) {
      setError('Failed to fetch topics. Please try again.')
    } finally {
      setIsLoading(false)
    }
  }

  const handleCreateTopic = async () => {
    if (newTopicKeyword.trim() === '' || newTopicRssLink.trim() === '') return

    setIsLoading(true)
    try {
      const idToken = await getIdTokenResult()
      const createTopicFunction = httpsCallable<{ keyword: string, rss_link: string, type: 'video' | 'article', idToken: string }, Topic>(functions, 'createTopic')
      await createTopicFunction({ 
        keyword: newTopicKeyword, 
        rss_link: newTopicRssLink,
        type: newTopicType,
        idToken
      })
      
      setSuccessMessage('Topic created successfully')
      setNewTopicKeyword('')
      setNewTopicRssLink('')
      setNewTopicType('video')
      fetchTopics() // Refresh the topics list
    } catch (error: any) {
      console.error('Error creating topic:', error)
      if (error.code === 'functions/resource-exhausted' || error.code === 'functions/not-found' || error.code === 'functions/internal') {
        setError(error.message)
      } else if (error.details) {
        setError(error.details)
      } else {
        setError('Failed to create topic. Please try again.')
      }
    } finally {
      setIsLoading(false)
    }
  }

  const handleDeleteTopic = async (topicId: string) => {
    setIsLoading(true)
    try {
      const idToken = await getIdTokenResult()
      const deleteTopicFunction = httpsCallable<{ topicId: string, idToken: string }, { message: string }>(functions, 'deleteTopic')
      await deleteTopicFunction({ topicId, idToken })
      
      setSuccessMessage('Topic deleted successfully')
      fetchTopics() // Refresh the topics list
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Failed to delete topic. Please try again.')
    } finally {
      setIsLoading(false)
    }
  }

  const columns: GridColDef[] = [
    { field: 'keyword', headerName: 'Keyword', flex: 1 },
    { field: 'rss_link', headerName: 'RSS Link', flex: 4, hideable: isMobile },
    { 
      field: 'type', 
      headerName: 'Type', 
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {params.value === 'video' ? (
            <YouTubeIcon color="error" sx={{ mr: 1 }} />
          ) : (
            <LanguageIcon color="primary" sx={{ mr: 1 }} />
          )}
          {params.value.charAt(0).toUpperCase() + params.value.slice(1)}
        </Box>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1 ,
      renderCell: (params: GridRenderCellParams) => (
        <IconButton 
          edge="end" 
          aria-label="delete" 
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteTopic(params.row.id);
          }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  // Calculate the height for 10 rows plus header
  const gridHeight = 52 * 11 + 56 // 52px per row, 56px for header

  return (
    <Box sx={{ maxWidth: 1200, margin: 'auto', mt: 1 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
        <TextField
          fullWidth
          variant="outlined"
          label="Topic Keyword"
          value={newTopicKeyword}
          onChange={(e) => setNewTopicKeyword(e.target.value)}
          sx={{ mb: 1 }}
        />
        <TextField
          fullWidth
          variant="outlined"
          label="RSS Link"
          value={newTopicRssLink}
          onChange={(e) => setNewTopicRssLink(e.target.value)}
          sx={{ mb: 1 }}
        />
        <TextField
          select
          fullWidth
          variant="outlined"
          label="Topic Type"
          value={newTopicType}
          onChange={(e) => setNewTopicType(e.target.value as 'video' | 'article')}
          sx={{ mb: 1 }}
        >
          <MenuItem value="video">Video</MenuItem>
          <MenuItem value="article">Article</MenuItem>
        </TextField>
        <Button
          variant="contained"
          onClick={handleCreateTopic}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={24} /> : 'Create Topic'}
        </Button>
      </Box>
      <Box sx={{ height: gridHeight, width: '100%' }}>
        <DataGrid
          rows={topics}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 25, 50]}
          checkboxSelection
          disableRowSelectionOnClick
          loading={isLoading}
          onRowClick={(params) => navigate(`/content/${params.row.id}`)}
          sx={{
            '& .MuiDataGrid-row': {
              maxHeight: '52px !important',
              minHeight: '52px !important',
            },
          }}
        />
      </Box>
      <Snackbar
        open={!!error || !!successMessage}
        autoHideDuration={6000}
        onClose={() => { setError(''); setSuccessMessage('') }}
      >
        <Alert 
          onClose={() => { setError(''); setSuccessMessage('') }} 
          severity={error ? "error" : "success"} 
          sx={{ width: '100%' }}
        >
          {error || successMessage}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default Topic