import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function MicrosoftIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 48 48">
      <path fill="#ff5722" d="M6 6H22V22H6z" />
      <path fill="#4caf50" d="M26 6H42V22H26z" />
      <path fill="#ffc107" d="M6 26H22V42H6z" />
      <path fill="#03a9f4" d="M26 26H42V42H26z" />
    </SvgIcon>
  );
}