import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, CircularProgress } from '@mui/material';
import { getFunctions, httpsCallable } from "firebase/functions";
import { initializeFunctions } from '../services/firebase';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface VideoSummary {
  summary: string;
}

export default function Video() {
  const { videoId } = useParams<{ videoId: string }>();
  const [summary, setSummary] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (videoId) {
      fetchVideoSummary(videoId);
    }
  }, [videoId]);

  const fetchVideoSummary = async (videoId: string) => {
    setLoading(true);
    setError(null);
    try {
      const functions = initializeFunctions();

      const getVideoSummary = httpsCallable<{ videoId: string }, VideoSummary>(functions, 'getVideoSummary');
      const result = await getVideoSummary({ videoId });
      setSummary(result.data.summary);
    } catch (err) {
      console.error("Error fetching video summary:", err);
      setError("Failed to fetch video summary. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  if (!videoId) {
    return <Typography color="error">No video ID provided</Typography>;
  }

  return (
    <Box sx={{ maxWidth: '800px', margin: 'auto' }}>
      <Box sx={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}>
        <iframe
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
          src={`https://www.youtube.com/embed/${videoId}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Box>
      <Box>
        {loading && <CircularProgress />}
        {error && <Typography color="error">{error}</Typography>}
        {summary && (
          <Box sx={{ 
            padding: 1, 
            borderRadius: 1,
            '& h1, & h2, & h3, & h4, & h5, & h6': {
              marginTop: 2,
              marginBottom: 1,
            },
            '& p': {
              marginBottom: 1,
            },
            '& ul, & ol': {
              paddingLeft: 3,
            },
          }}>
            <ReactMarkdown remarkPlugins={[remarkGfm]}>{summary}</ReactMarkdown>
          </Box>
        )}
      </Box>
    </Box>
  );
}